import React, { useEffect, useState } from 'react';
import filterFactory, { dateFilter, textFilter } from 'react-bootstrap-table2-filter';
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Box,
  Grid,
  Chip,
  IconButton,
  Tooltip,
  Typography,
  InputAdornment
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import DateRangeIcon from '@mui/icons-material/DateRange';
import FactoryIcon from '@mui/icons-material/Factory';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import FilterListIcon from '@mui/icons-material/FilterList';
import DownloadIcon from '@mui/icons-material/Download';

import { columnMapping, defaultSmallCol, printColumns } from './Columns';
import TransactionForm from './TransactionForm';
import { RightPanel } from '../Common/Panels/rightPanel';
import Spinner from '../Spinner';
import PaginationTable from '../Common/PaginationTable/paginationTable';
import CollapsibleRow from '../CollapsibleRow';
import { useAuth } from '../../hook/AuthProvider';
import { useFirebase } from '../../components/Firebase/context';

import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';

const ReportPageBase = () => {
  const { authUser } = useAuth();
  const firebase = useFirebase();
  const [loading, setLoading] = useState(false);
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [keyField, setKeyField] = useState('id');
  const [selectedCustomer, setSelectedCustomer] = useState('');
  const [selectedYear, setSelectedYear] = useState('');
  const [selectedFacility, setSelectedFacility] = useState('');
  const [selectedActivity, setSelectedActivity] = useState('');
  const [customers, setCustomers] = useState([]);
  const [years, setYears] = useState([]);
  const [facilities, setFacilities] = useState([]);
  const [activities, setActivities] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [rowToUpdate, setRowToUpdate] = useState(null);
  const [fileRows, setFileRows] = useState([]);
  const [editRows, setEditRows] = useState([]);

  const letters = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H'];
  const numbers = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'];

  const filters = [
    'All',
    'Flagged',
    'Unflagged',
    'Numbers',
    'Letters',
    '1 - Butane',
    '2 - Butane',
    '3 - Sulfur',
    '4 - Benzene',
    '5 - Other',
    'A - Date',
    'B - ID',
    'C - No Results',
    'D - Invalid Result',
    'E - Unit/Method Missing',
    'F - Numbers Not Found',
    'G - Numbers Found',
    'H - No Template'
  ];
  const [selectedFilter, setSelectedFilter] = useState('All');

  if (!rows.length) {
    firebase.data('butane').then((rows) => {
      setFileRows(rows);
      firebase.data('editbutane').then((editRows) => {
        setEditRows(editRows);
      });
    });
  }

  useEffect(() => {
    setTable();
  }, [
    selectedCustomer,
    selectedYear,
    selectedFacility,
    selectedActivity,
    selectedFilter,
    editRows
  ]);

  const changeRow = (id) => {
    const row = fileRows.find((r) => r.id === id);
    const editRow = editRows.find((r) => r.id === id);
    setRowToUpdate(editRow || row);
    setOpenModal(true);
  };

  const downloadPDF = (id) => {
    const row = fileRows.find((r) => r.id === id);
    if (!row.originalDocumentURL) {
      alert('No PDF available');
      return;
    }

    window.open(
      row.originalDocumentURL.replace('api.parseur.com/document/', 'files.fuelfolder.com/')
    );
  };

  const setFlagsAndTidy = (row) => {
    // clean sample date:
    if (row.sampleDate) {
      row.SampleDate = row.sampleDate.replace(/\n/g, '').trim();
      if (row.sampleDate.match(/\d{1,2}\/\d{1,2}\/\d{2,4}/)) {
        row.sampleDate = row.sampleDate.replace(/.*?(\d{1,2}\/\d{1,2}\/\d{2,4}).*/, '$1');
      } else if (row.sampleDate.match(/\d{1,2}\.\d{1,2}\.\d{2,4}/)) {
        row.sampleDate = row.sampleDate.replace(/\./g, '-');
      } else if (row.sampleDate.includes('-')) {
        row.sampleDate = row.sampleDate
          .replace(/.*?([a-zA-Z0-9]+\-[a-zA-Z0-9]+\-[a-zA-Z0-9]+).*/, '$1')
          .trim();
      } else {
        const test = Date.parse(row.sampleDate);
        if (test && !isNaN(test)) {
          row.sampleDate = new Date(test).toISOString().split('T')[0];
        }
      }
    }

    const flags = [];
    // EPA guidance checks
    row.inButane =
      row.iButane || row.nButane ? Number(row.iButane || 0) + Number(row.nButane || 0) : undefined;

    if (row.inButane && row.inButane < 85) {
      flags.push('1');
    }
    if (row.totalButane && Number(row.totalButane || 0) < 85) {
      flags.push('2');
    }
    if (row.sulfur && Number(row.sulfur || 0) > 10) {
      flags.push('3');
    }

    // data quality checks
    if (!row.sampleDate || row.sampleDate.includes(' ') || !row.sampleDate.match(/\d{2}/)) {
      flags.push('A');
    }

    if (!row.sampleId) {
      flags.push('B');
    }

    const resultCols = [
      'benzene',
      'iButane',
      'nButane',
      'totalButane',
      'sulfur',
      'rvp',
      'olefins',
      'aromatics'
    ];
    let resultCount = 0;
    for (const col of resultCols) {
      if (row[col] && row[col].match(/\d/i)) {
        resultCount += 1;
      }
      if (row[col] && row[col].match(/([a-z]|\..+\.)/i) && !flags.includes('D')) {
        flags.push('D');
      }
      if (row[col] && (!row[`${col}TestMethod`] || !row[`${col}Units`]) && !flags.includes('E')) {
        flags.push('E');
      }
    }

    if (resultCount === 0) {
      flags.push('C');
    }

    for (const key of Object.keys(row)) {
      if (key.match(/method/i) && row[key] && !row[key].match(/\d/i) && !flags.includes('F')) {
        flags.push('F');
      }
      if (key.match(/unit/i) && row[key] && row[key].match(/\d/i) && !flags.includes('G')) {
        flags.push('G');
      }
    }
    row.flags = flags.sort().join(',');
    return row;
  }; // setFlagsAndTidy

  const updateRow = (row) => {
    setLoading(true);
    const rowCopy = setFlagsAndTidy(JSON.parse(JSON.stringify(row)));
    const editRowsCopy = JSON.parse(JSON.stringify(editRows));
    let editRowIndex = editRowsCopy.findIndex((r) => r.id === row.id);

    if (editRowIndex > -1) {
      editRowsCopy[editRowIndex] = rowCopy;
    } else {
      editRowsCopy.push(rowCopy);
    }
    setEditRows(editRowsCopy);

    // setRows([]);
    // setTable();

    firebase
      .updateEditData('editbutane', rowToUpdate.id, row)
      .then(() => {
        setOpenModal(false);
        setRowToUpdate(null);
        setLoading(false);
      })
      .catch((error) => {
        console.log('Error updating data: ', error);
      });
  };

  function setTable(force = false) {
    setLoading(true);
    if (!fileRows?.length) {
      setLoading(false);
      return;
    }

    let tCustomers = [];
    let tYears = [];
    let tFacilities = [];
    let tActivities = [];

    const chosenRows = fileRows.filter(
      (row) =>
        (!selectedCustomer || row.customer === selectedCustomer) &&
        (!selectedYear || row.year === selectedYear) &&
        (!selectedFacility || row.facility === selectedFacility) &&
        (!selectedActivity || row.activity === selectedActivity) &&
        (selectedFilter === 'All' ||
          (row.flags && selectedFilter === 'Flagged') ||
          (!row.flags && selectedFilter === 'Unflagged') ||
          (numbers.some((n) => row.flags.includes(n)) && selectedFilter === 'Numbers') ||
          (letters.some((l) => row.flags.includes(l)) && selectedFilter === 'Letters') ||
          (row.flags &&
            String(row.flags).includes(selectedFilter.substring(0, 1)) &&
            selectedFilter.includes(' - ')))
    );

    for (const row of chosenRows) {
      if (!tCustomers.includes(row.customer)) {
        tCustomers.push(row.customer);
      }
      if (!tYears.includes(row.year)) {
        tYears.push(row.year);
      }
      if (!tFacilities.includes(row.facility)) {
        tFacilities.push(row.facility);
      }
      if (!tActivities.includes(row.activity)) {
        tActivities.push(row.activity);
      }
    }

    tCustomers = tCustomers.sort();
    tYears = tYears.sort();
    tFacilities = tFacilities.sort();
    tActivities = tActivities.sort();

    setCustomers(tCustomers);
    setYears(tYears);
    setFacilities(tFacilities);
    setActivities(tActivities);

    if (chosenRows.length) {
      const header = Object.keys(chosenRows[0]);
      const moreColumns = [
        ...columnMapping.default,
        {
          dataField: 'id',
          text: 'Actions',
          display: true,
          formatter: (cell) => (
            <div style={{ display: 'flex' }}>
              <Tooltip title="Edit">
                <IconButton aria-label="edit" size="small" onClick={() => changeRow(cell)}>
                  <EditIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Download">
                <IconButton aria-label="download" size="small" onClick={() => downloadPDF(cell)}>
                  <DownloadIcon />
                </IconButton>
              </Tooltip>
            </div>
          )
        }
      ];

      header.forEach((h) => {
        const foundHeader = moreColumns.find((c) => c.dataField === h);
        if (!foundHeader && !['index', 'id', 'fileName', 'originalDocumentURL'].includes(h)) {
          moreColumns.push({
            dataField: h,
            text: h,
            filter: h.endsWith('date') || h.startsWith('date') ? dateFilter() : textFilter(),
            sort: false,
            editable: false,
            ...defaultSmallCol
          });
        }
      });

      // have to overwrite fileRows with editRows where editRows have the same id as fileRows
      const finalRows = chosenRows.map((row) => {
        const editRow = editRows.find((r) => r.id === row.id);
        if (editRow) {
          return editRow;
        }
        return row;
      });

      setRows(finalRows);
      setColumns(moreColumns);
      setLoading(false);
    } else {
      setRows([]);
      setLoading(false);
    }
  }

  function resetDropdowns() {
    setLoading(true);

    let tCustomers = [];
    let tYears = [];
    let tFacilities = [];
    let tActivities = [];

    for (const row of fileRows) {
      if (!tCustomers.includes(row.customer)) {
        tCustomers.push(row.customer);
      }
      if (!tYears.includes(row.year)) {
        tYears.push(row.year);
      }
      if (!tFacilities.includes(row.facility)) {
        tFacilities.push(row.facility);
      }
      if (!tActivities.includes(row.activity)) {
        tActivities.push(row.activity);
      }
    }

    tCustomers = tCustomers.sort();
    tYears = tYears.sort();
    tFacilities = tFacilities.sort();
    tActivities = tActivities.sort();

    setCustomers(tCustomers);
    setYears(tYears);
    setFacilities(tFacilities);
    setActivities(tActivities);

    setSelectedCustomer('');
    setSelectedYear('');
    setSelectedFacility('');
    setSelectedActivity('');
    setSelectedFilter('All');
    setRows([]);
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '0.75rem'
      }}
    >
      <Typography variant="h4" sx={{ mt: 4, mb: 4 }}>
        Data Selection
      </Typography>
      <div style={{ display: 'flex', gap: '0.75rem' }}>
        <FormControl fullWidth>
          <InputLabel id="select-customer-label">Customer</InputLabel>
          <Select
            labelId="select-customer-label"
            value={selectedCustomer}
            label="Customer"
            onChange={(e) => setSelectedCustomer(e.target.value)}
            startAdornment={
              // This line adds the icon inside the Select input
              <InputAdornment position="start">
                <AccountCircleIcon />
              </InputAdornment>
            }
          >
            {customers.map((v) => {
              return (
                <MenuItem key={`menuItem-customer-${v}`} value={v}>
                  {v}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <FormControl fullWidth>
          <InputLabel size="large" id="select-year-label">
            Year
          </InputLabel>
          <Select
            size="large"
            labelId="select-year-label"
            value={selectedYear}
            label="Year"
            onChange={(e) => setSelectedYear(e.target.value)}
            startAdornment={
              // This line adds the icon inside the Select input
              <InputAdornment position="start">
                <DateRangeIcon />
              </InputAdornment>
            }
          >
            {years.map((v) => {
              return (
                <MenuItem key={`menuItem-year-${v}`} value={v}>
                  {v}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <FormControl fullWidth>
          <InputLabel size="large" id="select-facility-label">
            Facility
          </InputLabel>
          <Select
            size="large"
            labelId="select-facility-label"
            value={selectedFacility}
            label="Facility"
            onChange={(e) => setSelectedFacility(e.target.value)}
            startAdornment={
              // This line adds the icon inside the Select input
              <InputAdornment position="start">
                <FactoryIcon />
              </InputAdornment>
            }
          >
            {facilities.map((v) => {
              return (
                <MenuItem key={`menuItem-facility-${v}`} value={v}>
                  {v}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <FormControl fullWidth>
          <InputLabel size="large" id="select-activity-label">
            Activity
          </InputLabel>
          <Select
            size="large"
            labelId="select-activity-label"
            value={selectedActivity}
            label="Activity"
            onChange={(e) => setSelectedActivity(e.target.value)}
            startAdornment={
              // This line adds the icon inside the Select input
              <InputAdornment position="start">
                <DirectionsRunIcon />
              </InputAdornment>
            }
          >
            {activities.map((v) => {
              return (
                <MenuItem key={`menuItem-activity-${v}`} value={v}>
                  {v}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <FormControl fullWidth>
          <InputLabel size="large" id="select-filter-label">
            Filter
          </InputLabel>
          <Select
            size="large"
            labelId="select-filter-label"
            value={selectedFilter}
            label="Filter"
            onChange={(e) => setSelectedFilter(e.target.value)}
            startAdornment={
              // This line adds the icon inside the Select input
              <InputAdornment position="start">
                <FilterListIcon />
              </InputAdornment>
            }
          >
            {filters.map((v) => {
              return (
                <MenuItem key={`menuItem-filter-${v}`} value={v}>
                  {v}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <Button color="primary" variant="contained" onClick={resetDropdowns}>
          Reset
        </Button>
      </div>
      {loading && <Spinner loading={loading} />}
      {!loading && rows && rows.length > 0 && (
        <>
          <PaginationTable
            columns={columns}
            printColumns={printColumns}
            filter={filterFactory()}
            rows={rows}
            columnFilter={(c) => c.display}
            keyField={keyField}
            rowComponent={(row) => (
              <CollapsibleRow row={row} columns={columns} key={row?.[keyField]} />
            )}
            collapsible
          />
          <Box component="fieldset" sx={{ boxShadow: 1, marginTop: '1rem', padding: '0.5rem' }}>
            <Typography variant="h6" sx={{ mt: 1, mb: 4 }}>
              Flags
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <ul>
                  <li>1 - iButane + nButane &lt; 85</li>
                  <li>2 - totalButane &lt; 85</li>
                  <li>3 - sulfur &gt; 10</li>
                  <li>4 - benzene &ge; 0.03</li>
                </ul>
              </Grid>
              <Grid item xs={4}>
                <ul>
                  <li>A - Sample Date missing or invalid</li>
                  <li>B - Sample ID missing</li>
                  <li>C - No results found</li>
                  <li>D - At least one invalid result</li>
                </ul>
              </Grid>
              <Grid item xs={5}>
                <ul>
                  <li>E - Method and/or Units missing for at least one result</li>
                  <li>F - Numbers not found in one or more methods</li>
                  <li>G - Numbers found in one or more units</li>
                </ul>
              </Grid>
            </Grid>
          </Box>
        </>
      )}
      <RightPanel open={openModal} setOpen={setOpenModal}>
        <TransactionForm
          updateRow={updateRow}
          initialState={typeof rowToUpdate === 'object' ? rowToUpdate : undefined}
        />
      </RightPanel>
    </div>
  );
};

export default ReportPageBase;
